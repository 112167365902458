<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="8">
          <b-card-group>
            <b-card no-body class="p-4">
              <b-card-body>
                <b-form>
                  <h1 class="font-5xl">Bimbo Lab</h1>
                  <p class="text-muted font-2xl">Login Page</p>
                  <b-input-group class="mb-3" size="lg">
                    <b-input-group-prepend><b-input-group-text><i class="icon-user"></i></b-input-group-text></b-input-group-prepend>
                    <b-form-input v-model="kullanici_adi" type="text" class="form-control" placeholder="Username" autocomplete="username email" />
                  </b-input-group>
                  <b-input-group class="mb-4" size="lg">
                    <b-input-group-prepend><b-input-group-text><i class="icon-lock"></i></b-input-group-text></b-input-group-prepend>
                    <b-form-input v-model="parola" type="password" class="form-control" placeholder="Password" autocomplete="current-password" />
                  </b-input-group>
                  <b-row>
                    <b-col cols="12" class="text-center">
                      <b-button @click="girisYap()" block size="lg" variant="primary" class="px-4">Login</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
	<b-modal title="UYARI" ok-only ok-title="Tamam" text="Tamam" class="modal-danger" v-model="errors.success" @ok="errors.success = false" ok-variant="danger">
			{{ errors.message }}
	</b-modal>
  </div>
</template>

<script>
import { User } from '@/shared/http'; 

export default {
  name: 'Giris',
  data() {
	  return {
		  kullanici_adi: "",
		  parola: "",
		  errors: {
			  success: false,
			  message: null
		  }
	  }
  },
  methods: {
	  girisYap() {
        fetch(`${this.$api}/api/login`, {
          method: 'POST',
          body: JSON.stringify({
            KullaniciAdi: this.kullanici_adi,
            Sifre: this.parola
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(resp => resp.json())
        .then(obj => {
          if (!obj.Success) {
            this.errors.success = true
            this.errors.message = "Username or password is wrong."
          } else {
            const data = JSON.stringify(obj)
            localStorage.setItem('currentAdmin', data)
            this.$router.push('anasayfa')
          }
        })
	  }
  }
}
</script>
